import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import StaticImg from "../../../images/static2-img.jpg"
import StaticImg2 from "../../../images/static2-img2.jpg"
import StaticImg3 from "../../../images/static2-img3.jpg"
import "./StaticBanner.scss"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import ReactMarkdown from "react-markdown"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const StaticBanner = props => {
  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s?.charAt(0).toUpperCase() + s?.slice(1)
  }
  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms
  }

  const officeSort = props.areas?.sort((a, b) =>
    a.node.AreaName?.localeCompare(b.node.AreaName)
  )

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="static2-banner"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <picture>
            {/* <source media="(min-width: 992px)" srcSet={props.Banner_Image?.url} />
						<source media="(min-width: 768px)" srcSet={props.Banner_Image?.url} />
						<img src={props.Banner_Image?.url} alt={props.Banner_Image?.alternativeText} className="banner-img" /> */}
            <ImageTransform
              imagesources={props?.Banner_Image?.url}
              renderer="srcSet"
              imagename="page.Banner_Image.half"
              attr={{
                alt: props.Banner_Image?.alternativeText?props.Banner_Image?.alternativeText :props?.Banner_Title+"DNG Estate Agents",
                className: "banner-img",
              }}
              imagetransformresult={processedImages}
              id={props.id}
            ></ImageTransform>
          </picture>
          <Container className="banner-wrap">
            <Row>
              <Col sm="12">
                <div className="banner-content">
                  {props?.Banner_Title && (
                    <motion.h1 variants={contentItem}>
                      {props?.Banner_Title}
                    </motion.h1>
                  )}
                  {props?.Banner_Content && (
                    <motion.div variants={contentItem}>
                      <ReactMarkdown
                        source={props?.Banner_Content}
                        allowDangerousHtml
                      />
                    </motion.div>
                  )}
                </div>
                {props.Banner_Type === "Team" && (
                  <motion.div variants={contentItem}>
                    <Form
                      className="search-form"
                      action="javascript:;"
                      onSubmit={props.seearchonSubmitTeam}
                    >
                      <label className="d-xl-none">
                        {" "}
                        Name, Branch or Location:
                      </label>
                      <label className="d-none d-xl-block">
                        Name, Branch or Location:
                      </label>
                      <div className="d-md-flex">
                        <Form.Group onMouseLeave={props.onleavesearchTeam}>
                          <div className="s-box">
                            <Form.Control
                              value={capitalize(props.searchvalueTeam)}
                              onClick={props.seearchonClinkTeam}
                              onChange={props.seearchonChangeTeam}
                              type="text"
                              placeholder="e.g. James McKeon "
                            />
                            {props.searchvalueTeam && (
                              <Button
                                onClick={props.seearchonCloseTeam}
                                className="s-close"
                              >
                                X
                              </Button>
                            )}
                          </div>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                          Search
                        </Button>
                      </div>
                    </Form>
                  </motion.div>
                )}
                {props.Banner_Type === "Office" && (
                  <motion.div variants={contentItem}>
                    <Form
                      className="search-form"
                      action="javascript:;"
                      onSubmit={props.seearchonSubmit}
                    >
                      <label className="d-xl-none">Search by Location:</label>
                      <label className="d-none d-xl-block">
                        Search by Location:
                      </label>
                      <div className="d-md-flex">
                        <Form.Group onMouseLeave={props.onleavesearch}>
                          <div className="s-box">
                            <Form.Control
                              value={capitalize(props.searchvalue)}
                              onClick={props.seearchonClink}
                              onChange={props.seearchonChange}
                              type="text"
                              placeholder="e.g. Bray "
                            />
                            {props.searchvalue && (
                              <Button
                                onClick={props.seearchonClose}
                                className="s-close"
                              >
                                X
                              </Button>
                            )}
                          </div>

                          {props.showlist && (
                            <div className="showlocationlist">
                              <ul>
                                {props.areas?.length > 0 ? (
                                  officeSort?.map(({ node }, index) => {
                                    return (
                                      <li
                                        onClick={() => {
                                          props.setsearchvalue(
                                            node?.AreaName?.toLowerCase()
                                          )
                                          props.setshowlist(false)
                                          sessionStorage.setItem(
                                            "searchvalue",
                                            node?.AreaName.toLowerCase()
                                          )
                                        }}
                                      >
                                        {node?.AreaName}
                                      </li>
                                    )
                                  })
                                ) : (
                                  <li>No results Found</li>
                                )}
                              </ul>
                            </div>
                          )}
                        </Form.Group>
                        <Button type={"submit"} variant="primary">
                          Search
                        </Button>
                      </div>
                    </Form>
                  </motion.div>
                )}
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default StaticBanner
