import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import Image1 from "../../../images/DNGResidential_1.jpg"
import NewsForm from "@Components/SearchResults/Youtube/NewsForm"
import Image2 from "../../../images/DNGResidential_2.jpg"
import Image3 from "../../../images/DNGResidential_3.jpg"
import Image4 from "../../../images/DNGResidential_4.jpg"
import NoImage from "../../../images/no-image.png"
import contactImg1 from "../../../images/analysis_member.jpg"
import "./AnalysisResearch.scss"
import ReactMarkdown from "react-markdown"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"
import PlayVideo from "../../../Components/PlayVideo/PlayVideo"
import Modal from "react-bootstrap/Modal"
import TeamForm from "@Components/forms/teamcontact"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const AnalysisResearch = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        researchs(
          sort: "Date:desc"
          publicationState: LIVE
          where: { Publish: true }
        ) {
          id
          imagetransforms
          Title
          Name
          Email
          Designation
          Phone
          Image {
            url
            alternativeText
          }
          Tail_Image {
            url
            alternativeText
          }
          Date
          URL
        }
      }
    }
  `)

  const [researchs, setresearchs] = useState(data.glstrapi?.researchs)

  const postsPerPage = 8
  let arrayForHoldingPosts = []
  // const [] = useState(0);
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(8)
  const loopWithSlice = (start, end) => {
    const slicedPosts = researchs?.slice(0, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  const handleShowMorePosts = () => {
    loopWithSlice(
      postsToShow?.length > 16 ? next : 16,
      postsToShow?.length > 16 ? next + postsPerPage : 16 + postsPerPage
    )
    setNext(postsToShow?.length > 16 ? next + postsPerPage : 16 + postsPerPage)
  }

  useEffect(() => {
    loopWithSlice(0, 16)
  }, [])

  const AnalysisLest = [
    {
      Images: Image1,
      Date: "06 May 2021",
      Heading: "DNG Residential Market Review Q1 2021",
    },
    {
      Images: Image2,
      Date: "06 May 2021",
      Heading: "DNG Residential Market Review Q4 2020",
    },
    {
      Images: Image3,
      Date: "06 May 2021",
      Heading: "DNG Residential Market Review Q3 2020",
    },
    {
      Images: Image4,
      Date: "06 May 2021",
      Heading: "DNG Residential Market Review Q2 2020",
    },
  ]
  const ImageRender = ({ item }) => {
    let processedImages = JSON.stringify({})
    if (item?.imagetransforms?.Tail_Image_Transforms) {
      processedImages = item.imagetransforms.Tail_Image_Transforms
    }

    return (
      <ImageTransform
        imagesources={item?.Tail_Image?.url}
        renderer="srcSet"
        imagename="researchs.Tail_Image.tail"
        attr={{
          alt: item?.Tail_Image?.alternativeText
            ? item?.Tail_Image?.alternativeText
            : item.Title + " - DNG Estate Agents",
          class: "",
        }}
        imagetransformresult={processedImages}
        id={item.id}
      />
    )
  }

  return (
    <div className="analysis-block">
      <Container>
        <Row>
          <Col lg="6">
            {props?.Title && (
              <motion.div variants={fadeInFromLeft}>
                <ReactMarkdown source={props?.Title} allowDangerousHtml />
              </motion.div>
            )}
            {props?.Content && (
              <motion.div variants={fadeInFromLeft}>
                <ReactMarkdown source={props?.Content} allowDangerousHtml />
              </motion.div>
            )}
          </Col>
          {researchs && researchs[0]?.Image?.url && (
            <Col lg="6">
              <div className="dealer-wrap">
                <div className="img-wrap">
                  <img
                    src={researchs[0]?.Image?.url}
                    alt={
                      researchs[0]?.Image?.alternativeText
                        ? researchs[0]?.Image?.alternativeText +
                          " - DNG Estate Agents"
                        : researchs[0]?.Name + " - DNG Estate Agents"
                    }
                  />
                </div>
                <div className="dealer-right">
                  <span className="dealer-name">{researchs[0]?.Name}</span>
                  <span className="dealer-position">
                    {researchs[0]?.Designation}
                  </span>
                  <div className="person-contact">
                    {researchs[0]?.Phone && (
                      <Link
                        href={"tel:" + researchs[0]?.Phone}
                        className="phone-number"
                      >
                        <i className="icon-phone"></i> {researchs[0]?.Phone}
                      </Link>
                    )}
                    <a
                      href="javascript:;"
                      onClick={() => handleShow()}
                      className="mail-text d-none d-xl-flex"
                    >
                      <i className="icon-mail"></i>
                    </a>
                    <Modal
                      show={show}
                      centered={true}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                      dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                    >
                      <Modal.Header closeButton className="contact-close-btn">
                        <Modal.Title className="w-100">
                          Email to {researchs[0]?.Name}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="form">
                        <TeamForm
                          toemail={researchs[0]?.Email}
                          officeNumber={researchs[0]?.Phone}
                          teamMember={researchs[0]?.Name}
                        />
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>
        <Row className="col-wrap row-padding">
          {postsToShow.map((item, index) => {
            return (
              <>
                {index % 8 === 0 && index !== 0 && (
                  <NewsForm
                    executeScrolljoin={props.executeScrolljoin}
                    type="Research"
                  />
                )}
                <Col md="6" lg="3">
                  <div className="advantage-item">
                    <Link to={item.URL + "/ "}>
                      <picture>
                        {item?.Tail_Image?.url ? (
                          <ImageRender item={item} />
                        ) : (
                          <img
                            src={NoImage}
                            alt={item.Tail_Image?.alternativeText}
                          />
                        )}
                        {/* <img src={item.Tail_Image?.url} alt={item.Tail_Image?.alternativeText} /> */}
                      </picture>
                    </Link>
                    <div className="advantage-info">
                      <span className="date-text">
                        {moment(item?.Date).format("DD MMMM YYYY")}
                      </span>
                      <h3>
                        <Link to={item.URL + "/ "} className="research-title">
                          {item.Title}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </Col>
              </>
            )
          })}
        </Row>
      </Container>
      {postsToShow?.length !== researchs?.length && (
        <Container>
          <Row>
            <Col md="12">
              <div className="review-load">
                <button
                  className="btn-pagination btn btn-primary"
                  onClick={handleShowMorePosts}
                >
                  Load more
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default AnalysisResearch
