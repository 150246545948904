import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import { isMobileOnly, isTablet, isIPad13, isIOS13 } from "react-device-detect"
import "./CurrentVacancies.scss"
import { useStaticQuery, graphql } from "gatsby"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import ReactMarkdown from "react-markdown"
import Slider from "react-slick"
const MotionCol = motion(Col)
const CurrentVacancies = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        careers(
          sort: "Date:DESC"
          publicationState: LIVE
          where: { Publish: true }
        ) {
          Job_Title
          office {
            Address
          }
          Publish
          URL
          department {
            Name
          }
        }
      }
    }
  `)

  const [jobs, setJobs] = useState(data.glstrapi?.careers)
  const vacancyList = [
    {
      vacancyHeading: "Senior Sales Negotiator",
      vacancyLocation: "Head Office, Dublin 6",
      vacancyType: "Residential, Commercial",
      vacancyDetails: "+ view details",
    },
    {
      vacancyHeading: "Trainee Lettings Negotiator",
      vacancyLocation: "Donegal, Co. Donegal",
      vacancyType: "Residential",
      vacancyDetails: "+ view details",
    },
    {
      vacancyHeading: "Valuation Surveyor",
      vacancyLocation: "Roscommon, Co. Roscommon.",
      vacancyType: "Residential",
      vacancyDetails: "+ view details",
    },
    {
      vacancyHeading: "Property Manager",
      vacancyLocation: "Nenagh, Co. Tipperary",
      vacancyType: "New Homes",
      vacancyDetails: "+ view details",
    },
    {
      vacancyHeading: "Lettings Administrator",
      vacancyLocation: "Dublin 4",
      vacancyType: "Residential",
      vacancyDetails: "+ view details",
    },
    {
      vacancyHeading: "Entry Level Recruitment Consultant",
      vacancyLocation: "Head Office, Dublin 6",
      vacancyType: "O'Rahilly Place, Co. Wexford",
      vacancyDetails: "+ view details",
    },
  ]
  let [vacancyBlock, setCustomerReview] = useState([])
  let intialNumberOfReview = 6

  useEffect(() => {
    if (isMobileOnly) {
      intialNumberOfReview = 5
    } else if (isTablet || isIPad13 || isIOS13) {
      intialNumberOfReview = 6
    }
  }, [])
  var settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  }
  useEffect(() => {
    setCustomerReview(vacancyList.slice(0, intialNumberOfReview))
  }, [])
  return jobs?.length > 0 ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="current-vacancy"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col lg="12">
                <div className="block-heading">
                  {props.Title && (
                    <motion.div variants={contentItem}>
                      <ReactMarkdown source={props.Title} allowDangerousHtml />
                    </motion.div>
                  )}
                  {/* <button type="button" data-role="none" class="slide-arrow slide-prev"></button>
							<button type="button" data-role="none" class="slide-arrow slide-next"></button> */}
                </div>
              </Col>
              <InView {...inViewOptions}>
                {({ ref, inView }) => (
                  <MotionCol
                    ref={ref}
                    lg={12}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={contentItemStagger}
                  >
                    {/* <Slider {...settings}> */}

                    {jobs?.map((item, index) => {
                      return (
                        <motion.div
                          key={index}
                          custom={index}
                          variants={contentItem}
                          className="vacancy-list"
                        >
                          <ul>
                            <li className="vacancy-title">
                              <h3>{item?.Job_Title}</h3>
                            </li>
                            <li className="location-title">
                              <i className="icon-pin-light"></i>{" "}
                              {item?.office?.Address}
                            </li>
                            <li className="vacancy-type">
                              <i className="icon-building-light"></i>{" "}
                              {item?.department?.Name}
                            </li>
                            <li className="vacancy-details">
                              <Link to={item?.URL + "/ "}>+ view details</Link>
                            </li>
                          </ul>
                        </motion.div>
                      )
                    })}
                    {/* </Slider> */}
                  </MotionCol>
                )}
              </InView>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  ) : null
}

export default CurrentVacancies
