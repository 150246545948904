import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Dropdown } from "react-bootstrap"
// import Dropdown from 'react-bootstrap/Dropdown'
import "./FilterBar.scss"

const FilterBar = props => {
  const [department, setDepartment] = useState("Department")
  const departmentSelect = e => {
    setDepartment(e)
  }

  const [location, setLocation] = useState("Location")
  const locationSelect = e => {
    setLocation(e)
  }

  const [branch, setBranch] = useState("branch")
  const branchSelect = e => {
    setBranch(e)
  }

  const branchList= props.branchlist?.sort((a,b)=> a.Branch_Location.localeCompare(b.Branch_Location))
  
  const findOffice=props.branchlist?.map(data=> data.Office_Name)
  const dub=findOffice?.filter((item, index,arr) => arr.indexOf(item) !== index);
 
  
  return (
    <div className="filter-bar">
      <Container>
        <Row>
          <Col md="4">
            <p className="result-text">Showing {props.teamcount} people </p>
          </Col>
          <Col
            md="8"
            className="d-md-flex align-items-md-center justify-content-lg-end"
          >
            <span className="filter-heading">Filter by:</span>
            <div className="filter-list">
              {/* <Dropdown onSelect={props.teamDepartmentSelect} className="select-option">
								<Dropdown.Toggle id="dropdown-basic">
									{props.teamDepartment}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item eventKey="All Deparment">All Deparment</Dropdown.Item>

									{props.departments?.length > 0 ? props.departments.map((item, index) => {
										return (
											<Dropdown.Item eventKey={item.Name}>{item.Name}</Dropdown.Item>
										)
									})
										: null}

								</Dropdown.Menu>
							</Dropdown> */}

              <Dropdown
                onSelect={props.teamLocationSelect}
                className="select-option"
              >
                <Dropdown.Toggle id="dropdown-basic">
                  {props.teamLocation}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="All Location">
                    All Location
                  </Dropdown.Item>

                  {props.branchlist?.length > 0
                    ? branchList?.map((item, index) => {
                        return (
                          <Dropdown.Item eventKey={item.Branch_Location}>
                            {item.Branch_Location}
                          </Dropdown.Item>
                        )
                      })
                    : null}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown
                onSelect={props.teambranchSelect}
                className="select-option"
              >
                <Dropdown.Toggle id="dropdown-basic">
                  {props.teambranch}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="All Branch">
                    All Branch
                  </Dropdown.Item>

                  {props.branchlist?.length > 0
                    ? props.branchlist.map((item, index) => {

                      const addBranch=dub?.includes(item.Office_Name)?`${item.Office_Name} (${item.Branch_Location})`:item.Office_Name;

                        return (
                          <Dropdown.Item eventKey={item.Office_Name}>
                            {addBranch}
                          </Dropdown.Item>
                        )
                      })
                    : null}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FilterBar
