import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import PeopleImage1 from "../../../images/person-img.jpg"
import PeopleImage2 from "../../../images/person-img1.jpg"
import PlayVideo from "../../PlayVideo/PlayVideo"
import ScrollAnimation from "react-animate-on-scroll"
import NoImage from "../../../images/no-image.png"
import "./PeopleList.scss"
import { Container, Row, Col } from "react-bootstrap"
import { isMobileOnly, isTablet, isIPad13, isIOS13 } from "react-device-detect"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import Modal from "react-bootstrap/Modal"
import TeamForm from "@Components/forms/teamcontact"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const MotionCol = motion(Col)
const MotionRow = motion(Row)
const PeopleList = props => {
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(null)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setIndex(event)
    setShow(true)
  }

  const People = [
    {
      id: 1,
      image: PeopleImage1,
      videoId: "V8ksSGxo6no",
      PersonName: "Person Name",
      PositionName: "Position",
      BranchName: "Branch Name",
      PhoneNumber: "071 915 9222",
      EmailId: "person.name@dng.ie",
      BioText: "bio",
    },
    {
      id: 2,
      image: PeopleImage2,
      videoId: "V8ksSGxo6no",
      PersonName: "Person Name",
      PositionName: "Position",
      BranchName: "Branch Name",
      PhoneNumber: "071 915 9222",
      EmailId: "person.name@dng.ie",
      BioText: "bio",
    },
    {
      id: 3,
      image: PeopleImage1,
      videoId: "V8ksSGxo6no",
      PersonName: "Person Name",
      PositionName: "Position",
      BranchName: "Branch Name",
      PhoneNumber: "071 915 9222",
      EmailId: "person.name@dng.ie",
      BioText: "bio",
    },
    {
      id: 4,
      image: PeopleImage2,
      videoId: "V8ksSGxo6no",
      PersonName: "Person Name",
      PositionName: "Position",
      BranchName: "Branch Name",
      PhoneNumber: "071 915 9222",
      EmailId: "person.name@dng.ie",
      BioText: "bio",
    },
    {
      id: 5,
      image: PeopleImage1,
      videoId: "V8ksSGxo6no",
      PersonName: "Person Name",
      BranchName: "Branch Name",
      PositionName: "Position",
      PhoneNumber: "071 915 9222",
      EmailId: "person.name@dng.ie",
      BioText: "bio",
    },
    {
      id: 6,
      image: PeopleImage2,
      videoId: "V8ksSGxo6no",
      PersonName: "Person Name",
      PositionName: "Position",
      BranchName: "Branch Name",
      PhoneNumber: "071 915 9222",
      EmailId: "person.name@dng.ie",
      BioText: "bio",
    },
    {
      id: 7,
      image: PeopleImage1,
      videoId: "V8ksSGxo6no",
      PersonName: "Person Name",
      BranchName: "Branch Name",
      PositionName: "Position",
      PhoneNumber: "071 915 9222",
      EmailId: "person.name@dng.ie",
      BioText: "bio",
    },
    {
      id: 8,
      image: PeopleImage2,
      videoId: "V8ksSGxo6no",
      PersonName: "Person Name",
      PositionName: "Position",
      BranchName: "Branch Name",
      PhoneNumber: "071 915 9222",
      EmailId: "person.name@dng.ie",
      BioText: "bio",
    },
  ]

  let [reviewList, setCustomerReview] = useState([])
  let intialNumberOfReview = 8

  useEffect(() => {
    if (isMobileOnly) {
      intialNumberOfReview = 4
    } else if (isTablet || isIPad13 || isIOS13) {
      intialNumberOfReview = 4
    }
  }, [])

  useEffect(() => {
    setCustomerReview(People.slice(0, intialNumberOfReview))
  }, [])

  const [isPlay, setIsPlay] = useState(false)

  const playVideoHandler = () => {
    setIsPlay(true)
  }

  const postsPerPage = 16
  let arrayForHoldingPosts = []
  // const [] = useState(0);
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(16)
  const loopWithSlice = (start, end) => {
    const teamData = props.teams.map(team => team?.node)

    const headOffice=teamData?.filter(data=> data.offices?.[0]?.Office_Name==="DNG Head Office");
    const officeLists=teamData?.filter(data=> data.offices?.[0]?.Office_Name!=="DNG Head Office");
   


    //sorting team memeber's surname of head office  in alphabetical order
     const splitedheadOffice=headOffice.map(data=> {
      return { 
        ...data,
        Name: data.Name.split(" ")
      }
    });
    const sortedheadOffice=splitedheadOffice.sort((a, b) =>a.Name[1] && b.Name[1] && a.Name[1].localeCompare(b.Name[1]));
    
    const headOfficeList=sortedheadOffice.map(item =>{return {
        ...item,
        Name: item?.Name?.join(" ")
      }});

      //head office ends

      //rest of the offices sorting
      const splitOtherOffice=officeLists.map(data=> {
        return { 
          ...data,
          Name: data.Name.split(" ")
        }
      });

      const sortOtherOffice=splitOtherOffice.sort((a, b) =>a.Name[1] && b.Name[1] && a.Name[1].localeCompare(b.Name[1]));

      const otherOfficeList=sortOtherOffice.map(item =>{return {
        ...item,
        Name: item?.Name?.join(" ")
      }});
      //-------


   const totalOffices=[...headOfficeList,...otherOfficeList];

    // const result=teamData.map(data=> {
    //   return { 
    //     ...data,
    //     Name: data.Name.split(" ")
    //   }
    // });

    // const splitSortedArr = result.sort((a, b) =>a.Name[1] && b.Name[1] && a.Name[1].localeCompare(b.Name[1]))
    // const sortedArr = splitSortedArr.map(item =>{return {
    //   ...item,
    //   Name: item.Name.join(" ")
    // }})

    
    // const sortedData = teamData.sort((x, y) => x.Name.localeCompare(y.Name))
    const slicedPosts = totalOffices?.slice(0, end)

    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage)
    setNext(next + postsPerPage)
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
  }, [props.teams, props.teambranch])

  const ImageRender = ({ alt, image_url, node, processedImages }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="team.Image.tail"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node._id}
      />
    )
  }
  return (
    <React.Fragment>
      <div className="people-List-block">
        <Container>
          <InView {...inViewOptions}>
            {({ ref, inView }) => (
              <MotionRow
                ref={ref}
                className="row-padding"
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={contentItem}
              >
                {postsToShow?.length > 0 ? (
                  postsToShow?.map((node, i) => {
                    let processedImages = JSON.stringify({})
                    if (node?.imagetransforms?.Image_Transforms) {
                      processedImages = node.imagetransforms.Image_Transforms
                    }
                    return (
                      <Col md="6" lg="4" xl="3" key={i} className="people-grid">
                        <div className="people-block">
                          <div className="person-img">
                            <Link
                              to={node.URL + "/ "}
                              className="img-zoom img-wrap"
                            >
                              {node?.Image?.url ? (
                                <ImageRender
                                  alt={`${node.Name} ${node.Designation} - DNG Estate Agents`}
                                  processedImages={processedImages}
                                  image_url={node?.Image?.url}
                                  node={node}
                                />
                              ) : (
                                <img src={NoImage} alt="no-image" />
                              )}
                              {node.Video_Url && (
                                <button
                                  type="button"
                                  className="play-btn"
                                  onClick={playVideoHandler}
                                >
                                  <i className="icon-play"></i>
                                </button>
                              )}
                            </Link>
                          </div>
                          <div className="person-info">
                            <Link to={node.URL + "/ "}>
                              <span className="person-name">{node.Name}</span>
                              <span className="position-name">
                                {node.Designation}
                              </span>
                            </Link>
                            {/* <span className="branch-name d-none d-xl-block">{node.offices?.map((list, index) => (
													<>{list.Branch_Location}{index === node.offices?.length - 1 ? '' : ', '}</>
													))}
													</span> */}
                            {node.offices?.length > 0 ? (
                              <span className="branch-name d-none d-xl-block">
                                {node.offices.map((list, i) => (
                                  <span>
                                    {list.Office_Name}
                                    {i === node.offices?.length - 1 ? "" : ", "}
                                  </span>
                                ))}
                              </span>
                            ) : null}

                            {node.Email && (
                              <a
                                href="javascript:;"
                                onClick={() => handleShow(i)}
                                className="email-text d-block d-xl-none"
                              >
                                {node.Email}
                              </a>
                            )}
                            <div className="person-contact">
                              {node.Phone ? (
                                <Link
                                  href={"tel:" + node.Phone}
                                  className="phone-number"
                                >
                                  <i className="icon-phone"></i> {node.Phone}
                                </Link>
                              ) : node.Mobile_No ? (
                                <Link
                                  href={"tel:" + node.Mobile_No}
                                  className="phone-number"
                                >
                                  <i className="icon-phone"></i>{" "}
                                  {node.Mobile_No}
                                </Link>
                              ) : (
                                ""
                              )}
                              {node.Email && (
                                <a
                                  href="javascript:;"
                                  onClick={() => handleShow(i)}
                                  className="mail-text d-none d-xl-flex"
                                >
                                  <i className="icon-mail"></i> Email
                                </a>
                              )}
                              <Link
                                to={node.URL + "/ "}
                                className="arrow-symbol"
                              >
                                bio<i className="icon-arrow"></i>
                              </Link>
                              {i === index && (
                                <Modal
                                  show={show}
                                  centered={true}
                                  onHide={handleClose}
                                  backdrop="static"
                                  keyboard={false}
                                  dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                                >
                                  <Modal.Header
                                    closeButton
                                    className="contact-close-btn"
                                  >
                                    <Modal.Title className="w-100">
                                      Email to {node.Name}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body className="form">
                                    <TeamForm
                                      toemail={node.Email}
                                      officeNumber={node.Phone}
                                      teamMember={node.Name}
                                      branchName={node.offices[0]}
                                    />
                                  </Modal.Body>
                                </Modal>
                              )}
                            </div>
                          </div>
                        </div>
                        <PlayVideo
                          isOpen={isPlay}
                          stopPlay={setIsPlay}
                          videoId={node.Video_Url}
                          isAutoPlay={1}
                        />
                      </Col>
                    )
                  })
                ) : (
                  <Col md="12">
                    <div>No results Found</div>
                  </Col>
                )}
              </MotionRow>
            )}
          </InView>
        </Container>
        {postsToShow?.length !== props.teams?.length && (
          <Container>
            <Row>
              <Col md="12">
                <div className="review-load">
                  <button
                    className="btn-pagination btn btn-primary"
                    onClick={handleShowMorePosts}
                  >
                    Load more
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default PeopleList
